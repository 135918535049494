import React from "react"
import OpacityParallax from "@components/parallax-opacity"
import ParallaxSticky from "@components/parallax-sticky"

import Layout from "@components/layout"
import SEO from "@components/seo"
import { TextBlock } from "@components/story-components"
import { graphql, Link } from "gatsby"
import { FluidImg } from "src/types"
import { Text, Title } from "@ui/Typography"

import Image from "gatsby-image"

import styled from "@emotion/styled"
import ScrollDown from "@components/ScrollDown"

type Props = {
  data: {
    front: FluidImg
    military: FluidImg
    sign: FluidImg
    family: FluidImg
    child: FluidImg
    book: FluidImg
    socialize: FluidImg
  }
}

const ReadMore = styled(Text.withComponent(Link))`
  color: inherit;
  font-family: inherit;
`

const Img = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FindingsPage = ({ data }: Props) => {
  return (
    <Layout showFooter>
      <SEO title="Main findings" />
      <TextBlock>
        <Fixed>
          <Img fluid={data.front.childImageSharp.fluid} />
          <Title
            style={{ position: "relative" }}
            color="wheat"
            textAlign="center"
            maxWidth="53rem"
            as="h1"
          >
            Main findings
          </Title>
          <ScrollDown
            position="absolute"
            bottom="3rem"
            my="auto"
            href="#resolve-conflict"
          />
        </Fixed>
      </TextBlock>
      <OpacityParallax
        bg="wine"
        threshold={0.5}
        backgroundProps={{
          fluid: data.military.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock id="resolve-conflict" color="wheat" margin="auto">
            <Text fontSize="4xl" textAlign="center" as="h2" m="0">
              Resolve conflict
            </Text>
            <Text my={["lg", "xl"]} fontSize={["lg", "xl"]} maxWidth="70ch">
              Conflict is a key driver of violent extremism in South-East Asia.
              In a region where there are several ongoing conflicts, it is
              imperative that governments support targeted and inclusive
              policies that differentiate between insurgent and extremist
              violence, and address local grievances as well as socio-political
              and economic drivers.
            </Text>
            <Text variant="effect">
              Read more in:{" "}
              <ReadMore to="/about#no-violence">
                "As Long As They Don't Use Violence”
              </ReadMore>
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <OpacityParallax
        bg="airdust"
        threshold={0.5}
        backgroundProps={{
          fluid: data.sign.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat" margin="auto">
            <Text fontSize="4xl" textAlign="center" as="h2" m="0">
              Reduce Violence
            </Text>
            <Text mt={["lg", "xl"]} fontSize={["md", "xl"]} maxWidth="80ch">
              Extremism thrives in environments where violence, hate speech and
              discrimination against minorities is accepted or has become
              normalised. Governments and stakeholders should address the causes
              of worsening tensions, marginalisation and violence, especially as
              they relate to government policy, rhetoric and action.
            </Text>
            <Text variant="effect">
              Read more in:{" "}
              <ReadMore to="/about#state-of-violence">
                “State of violence”
              </ReadMore>
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <OpacityParallax
        bg="viridian"
        threshold={0.5}
        backgroundProps={{
          fluid: data.socialize.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat" margin="auto">
            <Text fontSize="4xl" textAlign="center" as="h2" m="0">
              Reintegrate
            </Text>
            <Text mt={["lg", "xl"]} fontSize={["lg", "xl"]} maxWidth="60ch">
              A reality and risk for South-East Asian communities is the return
              of individuals and their families who left to fight in Iraq and
              Syria, or to live an imagined better life in the Caliphate. Many
              governments also face the prospect of releasing convicted
              terrorists from prison. Improved prison conditions and
              individualized approaches that involve working with families,
              communities and religious leaders will be essential in sustainably
              reducing the risk of further violence.
            </Text>
            <Text variant="effect">
              Read more in:{" "}
              <ReadMore to="/about#homecoming">“Homecoming”</ReadMore>
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <OpacityParallax
        bg="wine"
        threshold={0.5}
        backgroundProps={{
          fluid: data.book.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat" margin="auto">
            <Text fontSize={["3xl", "4xl"]} textAlign="center" as="h2" m="0">
              Challenge Assumptions
            </Text>
            <Text mt={["lg", "xl"]} fontSize={["lg", "xl"]} maxWidth="60ch">
              Assumptions around the threat of violent extremism in South-East
              Asia, coupled with a lack of grounded evidence, has led to the
              funding and implementation of programs and policies that may do
              more harm. These approaches need to be challenged with
              evidence-based, context-specific research. Further data, research
              and communities of practice will be vital to identify and create
              monitoring systems and knowledge that provide governments and
              societies with the tools to understand and act on growing
              extremist views and violence in their communities as new
              challenges arise.
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <OpacityParallax
        bg="midnight"
        threshold={0.5}
        backgroundProps={{
          fluid: data.family.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat" margin="auto">
            <Text fontSize="4xl" textAlign="center" as="h2" m="0">
              End discrimination and promote inclusivity.
            </Text>
            <Text mt={["lg", "xl"]} fontSize={["lg", "xl"]} maxWidth="70ch">
              Harmful narratives and discrimination against minority groups
              leads to increasingly polarized environments that are conducive to
              violent extremism. Governments and stakeholders most uphold
              inclusive policies, and address hate speech based on ethnicity,
              religion, gender and other identities. To prevent violent
              extremism, consultative and whole-of-community approaches are
              essential.
            </Text>
            <Text variant="effect">
              Read more in:{" "}
              <ReadMore to="/about#state-of-violence">
                “State of violence”
              </ReadMore>{" "}
              and{" "}
              <ReadMore to="/about#reports">
                “Entry and Exit Points: Violent Extremism in South-East Asia”
              </ReadMore>
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
      <OpacityParallax
        bg="cyprus"
        threshold={0.5}
        backgroundProps={{
          fluid: data.child.childImageSharp.fluid,
        }}
      >
        <ParallaxSticky mt="120vh">
          <TextBlock color="wheat" margin="auto">
            <Text fontSize={["3xl", "4xl"]} textAlign="center" as="h2" m="0">
              Promote Human Rights
            </Text>
            <Text mt={["lg", "xl"]} fontSize={["lg", "xl"]} maxWidth="60ch">
              Responses to violent extremism need to carefully balance the need
              for security with firm grounding in the rule of law, protection of
              human rights, and evidence. Approaches to prevent violent
              extremism that are grounded in the promotion of human rights are
              more likely to promote sustainable peace well-being and less
              likely to elicit potential backlash contributing to violence.
            </Text>
          </TextBlock>
        </ParallaxSticky>
      </OpacityParallax>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    front: file(relativePath: { eq: "findings/front.jpg" }) {
      ...BlockImage
    }
    military: file(relativePath: { eq: "findings/military.jpg" }) {
      ...BlockImage
    }
    sign: file(relativePath: { eq: "findings/sign.jpg" }) {
      ...BlockImage
    }
    family: file(relativePath: { eq: "findings/family.jpg" }) {
      ...BlockImage
    }
    child: file(relativePath: { eq: "findings/child.jpg" }) {
      ...BlockImage
    }
    book: file(relativePath: { eq: "findings/book.jpg" }) {
      ...BlockImage
    }
    socialize: file(relativePath: { eq: "findings/socialize.jpg" }) {
      ...BlockImage
    }
  }
`

export default FindingsPage
